* {
  box-sizing: border-box;
}
@font-face {
  font-family: "Inter-Regular";
  src: url(./assets/fonts/Inter-Regular.woff) format("opentype")
}

@font-face {
  font-family: "Inter-Medium";
  src: url(./assets/fonts/Inter-Medium.woff) format("opentype")
}
@font-face {
  font-family: "Inter-SemiBold";
  src: url(./assets/fonts/Inter-SemiBold.woff) format("opentype")
}
@font-face {
  font-family: "Roboto-Regular";
  src: url(./assets/fonts/Roboto-Regular.woff) format("opentype")
}

 body, #root {
  background-color: #131313;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  position: relative;
}

#root {
  color: white;
}

@media only screen and (max-width: 37.5rem) {
  #root {
    background-size: 35.375rem;
  }
}

p {
  margin: 0;
}
